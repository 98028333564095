import Vue from 'vue';
// 按钮
import zgyButton from './zgy-button/zgy-button.vue';
Vue.component('zgy-button', zgyButton);
// 遮罩
import zgyMask from './zgy-mask/zgy-mask.vue';
Vue.component('zgy-mask', zgyMask);
// 登录
import zgyLogin from './zgy-login/zgy-login.vue';
Vue.component('zgy-login', zgyLogin);
// 搜索
import zgySearch from './zgy-search/zgy-search.vue';
Vue.component('zgy-search', zgySearch);
// 视频列表
import zgyVideoList from './zgy-videoList/zgy-videoList.vue';
Vue.component('zgy-video-list', zgyVideoList);
// 课程列表
import zgyCourseList from './zgy-course-list/zgy-course-list.vue';
Vue.component('zgy-course-list', zgyCourseList);
// 面包屑
import zgyCrumb from './zgy-crumb/zgy-crumb.vue';
Vue.component('zgy-crumb', zgyCrumb);