<template>
    <div class="mask">
        <div class="maskBox" v-show="mode"></div>
    </div>
</template>

<script>
export default {
    props: {
        mode: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        close() {
            this.mode = false;
        },
        open() {
            this.mode = true;
        }
    }
}
</script>

<style lang="scss" scoped>
.maskBox {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    background: #000;
    z-index: 2177;
}
</style>