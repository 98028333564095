<template>
  <div class="course_box">
    <div :class="{ wrapMargin: (index + 1) % marginIndex == 0 }" class="wrapper" v-for="(item, index) in list"
      :key="index" @click="change(index)">
      <div class="box clickable" :title="titleDispose(item)" @mouseover="mouseover(index)"
        @mouseleave="mouseleave(index)">
        <div class="image img-object-fit">
          <img :src="item.cover" class="image-img img-object-fit" :class="[`image-img${index}`]" />
          <span class="image-name">共{{ item.video_number > 0 ? item.video_number : 0 }}节</span>
        </div>

        <div class="content">
          <div class="content-title oneBeyond" :class="[`title${index}`]">{{ item.name }}</div>
          <!-- <div class="content-ul flexRow-alC" v-if="item.category_names && item.category_names.length > 0">
            <div class="content-li oneBeyond" v-for="(iconItem, iconIndex) in item.category_names" :key="iconIndex"
              :title="iconItem">
              {{ iconItem }}
            </div>
          </div> -->
          <!-- <div class="label">{{ item.play_number > 0 ? item.play_number : 0 }}次播放</div> -->
        </div>

        <!-- <div class="mark" v-show="avtiveIndex == index"></div> -->
      </div>

      <div class="section noSelect" v-if="categoryStatus">
        <div class="openAll clickable" @click.stop="openCatalogue(index, item.childrenStatus)">{{item.childrenStatus ? '收起' : '展开'}}</div>
        <div class="list flexColumn" @click.stop="sectionClick()">
          <div class="list-box" v-for="(child, childIndex) in item.children" :key="childIndex">
            <div class="flexRow-alC clickable" @click.stop="catalogueChange(childIndex, index)">
              <i class="el-icon-arrow-right list-icon" v-show="!child.status"></i>
              <i class="el-icon-arrow-down list-icon" v-show="child.status"></i>
              <span class="oneBeyond li-title" :title="child.name">{{ child.name }}</span>
            </div>
            <el-collapse-transition>
              <div class="ul flexColumn" v-show="child.status && child.children.length > 0">
                <div class="li flexRow-alC ft14 clickable" v-for="(subItem, subIndex) in child.children" :key="subIndex"
                  @click.stop="subCatalogueChange(subItem, index, subIndex)">
                  <span class="oneBeyond activeFont" :title="subItem.name">{{ subItem.name }}</span>
                </div>
              </div>
            </el-collapse-transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    marginIndex: {
      type: Number,
      default: 5,
    },
    categoryStatus: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      makrVshow: false,
      avtiveIndex: -1,

      list: []
    };
  },

  methods: {
    // 获取数据
    getData(value) {
      this.list = JSON.parse(JSON.stringify(value));
    },

    // 提示拼接
    titleDispose(item) {
      if (item.category_names.length > 0) return `${item.category_names.join(' • ')} • ${item.name}`
      return item.name
    },

    // wrapper鼠标移入
    mouseover(index) {
      this.avtiveIndex = index;
      this.editStyle("var(--theme_global_color)", true, 1.05, index);
    },
    // wrapper鼠标移出
    mouseleave(index) {
      this.avtiveIndex = -1;
      this.editStyle("#000", false, 1, index);
    },

    // 修改样式
    editStyle(color, boolean, multiple, index) {
      this.makrVshow = boolean;
      var selectDiv = document.querySelector(`.title${index}`);
      selectDiv.style.color = color;
      const imgDiv = document.querySelector(`.image-img${index}`);
      imgDiv.style.transform = `scale(${multiple})`;
    },
    // 点击
    change(index) {
      if (!this.$isTokenORVip()) return;

      this.$emit("change", index);
    },

    /**
     * @目录一级点击
     */
    catalogueChange(childIndex, index) {
      this.$emit('catalogueChange', { index: index, childIndex: childIndex });
    },
    /**
     * @目录二级点击
     */
    subCatalogueChange(subItem, index, subIndex) {
      if (!this.$isTokenORVip()) return;
      
      this.$emit('subCatalogueChange', { subIndex: subIndex, index: index, subItem: subItem })
    },
    // 展开全部
    openCatalogue(index, status) {
      this.$emit('openCatalogue', {index: index, status: status});
    },

    // 占位
    sectionClick() { }
  },
};
</script>

<style lang="scss" scoped>
.course_box {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .wrapMargin {
    margin-right: 0 !important;
  }

  .wrapper {
    position: relative;
    width: 214px;
    transition: all 0.3s;
    margin-right: 30px;
    margin-bottom: 20px;
    border: 1px solid #f2f2f2;
    border-radius: 5px;

    .box {
      width: 100%;

      .image {
        width: 100%;
        height: 120px;
        border-radius: 4px 4px 0 0;
        overflow: hidden;
        position: relative;

        .image-img {
          width: 100%;
          height: 100%;
          transition: all 0.3s ease-in-out;
        }

        .image-name {
          position: absolute;
          right: 0;
          bottom: 0;
          z-index: 1;
          height: 25px;
          line-height: 25px;
          border-radius: 4px 0 0 0;
          color: #fff;
          font-size: 12px;
          background: rgba(0, 0, 0, 0.6);
          padding: 0 10px;
        }
      }

      .content {
        border-top: 1px solid #f2f2f2;
        border-radius: 0 0 4px 4px;
        padding: 10px 0;
        position: relative;

        .content-title {
          font-size: 16px;
          font-weight: 600;
          color: #333;
          height: 20px;
          line-height: 20px;
          padding: 0 10px;
        }

        .content-ul {
          padding: 0 10px;
          margin-top: 10px;
          font-size: 13px;

          .content-li {
            margin-right: 2%;
            min-width: 10px;
            max-width: 30%;
            padding: 3px 5px;
            text-align: center;
            background: #eff1f4;
            color: #8d9095;
            border-radius: 3px;
          }
        }

        .label {
          padding: 0 10px;
          margin-top: 10px;
          color: #999;
          font-size: 12px;
        }
      }

      .mark {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        background: rgba(255, 255, 255, 0.2);
      }
    }

    .section {
      padding-bottom: 10px;

      .openAll {
        padding: 0 10px;
        margin-bottom: 5px;
        text-align: right;
        font-size: 14px;
        color: var(--theme_global_color);
      }

      .list {
        padding: 0 10px;
        font-size: 16px;
        overflow-y: auto;
        height: 145px;
        align-items: flex-start;

        .list-box {
          width: 100%;
          margin-bottom: 10px;

          span {
            font-size: 14px;
            font-weight: 600;
          }

          .list-icon {
            font-size: 12px;
            padding-right: 5px;
          }

          .ul {
            width: 100%;
            align-items: flex-start;

            .li {
              width: calc(100% - 30px);
              margin-top: 12px;
              margin-left: 30px;

              span {
                color: var(--remarks_color);
                font-size: 12px;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
}
</style>
