import axios from "axios";
import qs from "qs";
import { Message } from "element-ui";
import router from "@/router/index.js";
import store from '@/store/index';
/**
 * 参考文档 https://github.com/axios/axios
 */
// 获取缓存token
let token = localStorage.getItem('token');
window.addEventListener('setItem', () => {
    token = localStorage.getItem('token');
})

class Request {
    constructor(params) {
        this.baseURL = params.baseURL;
    }

    // 获取axios实例的配置
    getInsideConfig() {
        const config = {
            baseURL: this.baseURL,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "token": token
            },
            timeout: 50000,
            withCredentials: true,
        };
        return config;
    }

    interceptors(instance, tips) {
        // 请求拦截器
        instance.interceptors.request.use(
            (config) => {
                // Do something before request is sent
                return config;
            },
            (error) => {
                // Do something with request error
                return Promise.reject(error);
            }
        );

        // 响应拦截器
        instance.interceptors.response.use(
            (res) => {
                // Any status status that lie within the range of 2xx cause this function to trigger
                // Do something with response data
                if (res.data.code === 401) {
                    if (router.currentRoute.path !== "/") {
                        localStorage.removeItem('token');
                        localStorage.removeItem('userInfo');
                        store.dispatch('addToken', '');
                        store.dispatch('addUserInfo', {});
                        store.dispatch('addLoginStauts', { status: true, index: 0 });
                        router.replace({
                            path: '/'
                        })
                    }
                }

                switch (res.data.code) {
                    case 200:
                        break;
                    case 401:
                        break;
                    // 会员
                    case 403:
                        if (tips !== 'none') {
                            Message.error({ showClose: true, message: res.data.msg });
                        }
                        break;
                    default:
                        Message.error({ showClose: true, message: res.data.msg });
                        break;
                }

                return res;
            },
            (error) => {
                // Any status codes that falls outside the range of 2xx cause this function to trigger
                // Do something with response error
                return Promise.reject(error);
            }
        );
    }

    // 创建实例
    request(options, tips) {
        const instance = axios.create();
        const newOptions = Object.assign(this.getInsideConfig(), options);
        this.interceptors(instance, tips);

        // 等价于 instance.request(newOptions)
        return instance(newOptions);
    }

    get(url, data, tips, config) {
        const options = Object.assign(
            {
                method: "get",
                url: url,
                params: data,
                header: {
                    "token": token
                }
            },
            config,
        );
        return this.request(options, tips);
    }

    post(url, data, tips, type, headers) {
        return this.request({
            method: "post",
            url: url,
            data: type ? data : qs.stringify(data),
            headers: {
                "Content-Type": type
                    ? type
                    : "application/x-www-form-urlencoded",
                "token": token,
                ...headers,
            }
        }, tips);
    }

    put(url, data, tips, type, headers) {
        return this.request({
            method: "put",
            url: url,
            data: type ? data : qs.stringify(data),
            headers: {
                "Content-Type": type
                    ? type
                    : "application/x-www-form-urlencoded",
                "token": token,
                ...headers,
            },
        }, tips);
    }

    delete(url, data, tips, type, headers) {
        return this.request({
            method: "delete",
            url: url,
            data: type ? data : qs.stringify(data),
            headers: {
                "Content-Type": type
                    ? type
                    : "application/x-www-form-urlencoded",
                "token": token,
                ...headers,
            }
        }, tips);
    }
}

const request = new Request({
    baseURL: process.env.VUE_APP_API_URL,
});

export default request;