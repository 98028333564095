import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'homePage',
    component: () => import('../views/homePage.vue'),
    meta: {
      title: '首页'
    },
    redirect: "/home",
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/subView/home.vue'),
        meta: {
          title: '首页',
          label: '首页'
        }
      },
      {
        path: '/course',
        name: 'course',
        component: () => import('../views/subView/course/index.vue'),
        meta: {
          title: '课程分类'
        },
        redirect: "/course/list",
        children: [
          {
            path: '/course/list',
            name: 'course/list',
            component: () => import('../views/subView/course/list.vue'),
            meta: {
              label: '课程分类'
            },
          },
          {
            path: '/course/detail',
            name: 'course/detail',
            component: () => import('../views/subView/course/detail.vue'),
            meta: {
              title: '同步课堂',
              label: '同步课堂'
            }
          }
        ]
      },
      {
        path: '/user',
        name: 'user',
        component: () => import('../views/subView/user/index.vue'),
        meta: {
          title: '个人中心'
        },
        redirect: "/user/basic_info",
        children: [
          {
            path: '/user/basic_info',
            name: 'user/basic_info',
            component: () => import('../views/subView/user/basic_info.vue'),
            meta: {
              title: '基础信息',
              label: '基础信息'
            }
          },
          {
            path: '/user/history',
            name: 'user/history',
            component: () => import('../views/subView/user/history.vue'),
            meta: {
              title: '观看记录',
              label: ''
            }
          }
        ]
      },
      {
        path: '/information',
        name: 'information',
        component: () => import('../views/subView/information/index.vue'),
        meta: {
          title: '教育资讯',
          label: '教育资讯'
        },
        redirect: '/information/list',
        children: [
          {
            path: '/information/list',
            name: 'information/list',
            component: () => import('../views/subView/information/list.vue'),
            meta: {
              label: '教育资讯'
            }
          },
          {
            path: '/information/detail',
            name: 'information/detail',
            component: () => import('../views/subView/information/detail.vue'),
            meta: {
              title: '资讯详情',
              label: '资讯详情'
            }
          }
        ]
      },
      {
        path: '/search',
        name: 'search',
        component: () => import('../views/subView/search/index.vue'),
        meta: {
          title: '',
          label: '搜索'
        },
      },
      {
        path: '/protocol',
        name: 'protocol',
        component: () => import('../views/subView/user/protocol.vue'),
        meta: {
          title: '官方信息',
          label: '官方信息'
        },
      }
    ]
  }
]

const router = new VueRouter({
  mode: "hash",
  // base: process.env.BASE_URL,
  routes
});

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;
// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;
// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}


import { identityVerify } from '@/global/global_commJS/global_commJS';

// 全局导航守卫
router.beforeEach((to, from, next) => {
  if (to.meta.label) {
    document.title = to.meta.label + ' - 提分赢'
  } else {
    document.title = '提分赢' // 默认的title
  }

  // 滚动条
  let scrollTop =
    window.pageYOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop;

  // 身份验证
  if (to.path == '/course/detail') {
    identityVerify(to.query.id).then((res) => {
      if (res.data) {
        if (scrollTop > 0) {
          scrollTop = 0;
          document.body.scrollTop = document.documentElement.scrollTop =
            scrollTop;
        }
        return next();
      }
    })
    return;
  }

  if (scrollTop > 0) {
    scrollTop = 0;
    document.body.scrollTop = document.documentElement.scrollTop =
      scrollTop;
  }
  return next();
});

export default router
