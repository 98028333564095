<template>
    <div class="zgy-search">
        <div class="flexRow-alC" :style="{ height: heightData + 'px', width: widthData + 'px' }">
            <div class="tips flexRow-alC" v-if="tips">共<span>{{ tipsNumber }}</span>条</div>

            <el-popover placement="bottom-start" title="" :width="widthData - 150" trigger="focus" content=""
                :value="popoverValue" v-if="searchHistory">
                <div :style="{ width: widthData - 120 + 'px' }" slot="reference">
                    <el-input :placeholder="placeholder" v-model.trim="keyword" @keyup.enter.native="change" @clear="change"
                        @focus="inputFocus" :clearable="clearable" @change="inputChange">
                        <i class="el-icon-search el-input__icon headerIcon" slot="prefix"></i>
                    </el-input>
                </div>

                <div class="zs-history">
                    <div class="zsh-title">历史记录</div>
                    <div class="zsh-li" v-for="(item, index) in history" :key="index" @click="historyChange(item)">{{
                        item.value }}</div>
                </div>
            </el-popover>

            <el-input :placeholder="placeholder" v-model.trim="keyword" @keyup.enter.native="change" @clear="change"
                @focus="inputFocus" :clearable="clearable" @change="inputChange" v-else>
                <i class="el-icon-search el-input__icon headerIcon" slot="prefix"></i>
            </el-input>

            <div class="button">
                <zgy-button text="搜索" :customStyle="{ 'border-top-left-radius': 0, 'border-bottom-left-radius': 0 }"
                    @change="change"></zgy-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        // 搜索字段
        value: {
            type: String,
            default: ''
        },
        // 高度
        heightData: {
            type: Number,
            default: 40
        },
        // 宽度
        widthData: {
            type: Number,
            default: 360
        },
        // 提示语
        placeholder: {
            type: String,
            default: ''
        },
        // 按钮名称
        btnName: {
            type: String,
            default: ''
        },
        // 提示
        tips: {
            type: Boolean,
            default: false
        },
        // 提示内数量
        tipsNumber: {
            type: Number,
            default: 0
        },
        // 历史记录
        searchHistory: {
            type: Boolean,
            default: false
        },
        // 输入框是否有清空按钮
        clearable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            keyword: '',

            popoverValue: false
        }
    },

    computed: {
        history() {
            return this.$store.state.search_history
        }
    },

    mounted() {
        this.keyword = this.value;
    },

    methods: {
        getKeyword(data) {
            this.keyword = data;
        },

        close() {
            this.keyword = '';
        },

        change() {
            this.popoverValue = false;
            this.$emit('change', this.keyword)
        },

        inputChange() {
            this.$emit('change', this.keyword)
        },

        historyChange(item) {
            this.$emit('historyChange', item);
            this.popoverValue = false;
        },

        inputFocus() {
            this.popoverValue = true;
        }
    }
}
</script>

<style lang="scss" scoped>
.zgy-search {

    .tips {
        padding-right: 10px;

        span {
            padding: 0 2px;
            color: var(--theme_global_color);
        }

    }

    .button {
        position: relative;
        z-index: 1;
        left: -5px;
    }

    .headerIcon {
        height: 0;
    }
}

.zs-history {
    margin: 0 -12px;

    .zsh-title {
        padding: 0 24px;
        margin-bottom: 10px;
        color: #999;
        font-size: 12px;
    }

    .zsh-li {
        padding: 7px 24px;
        margin: 0;
        cursor: pointer;
        color: #606266;
        font-size: 14px;
        list-style: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .zsh-li:hover {
        background: #f0f0f0c0;
    }
}
</style>