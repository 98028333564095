
<script>
export default {
  props: {
    type: {
      type: String,
      default: "primary",
    },
    color: {
      type: String,
      default: "#fff",
    },
    background: {
      type: String,
      default: "var(--theme_global_color)",
    },
    width: {
      type: String,
      default: "60px",
    },
    height: {
      type: String,
      default: "38px",
    },
    text: {
      type: String,
      default: "",
    },
    customStyle: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  render() {
    return (
      <div
        class={{ btn: true, btnActive: true, btnPlain: this.type == "plain" }}
        style={{
          width: this.width,
          height: this.height,
          color: this.color,
          background: this.background,
          lineHeight: this.height,
          ...this.customStyle,
        }}
        onClick={this.handleClick}
      >
        {this.text}
      </div>
    );
  },
  methods: {
    handleClick() {
      this.$emit("change");
    },
  },
};
</script>

<style scoped>
.btn {
  text-align: center;
  border: 1px solid transparent;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  transition: .1s;
}

.btn:hover {
  opacity: 0.99;
}

.btnPlain {
  border-color: #dcdfe6;
}

.btnPlain:hover {
  border-color: var(--theme_global_color);
  color: var(--theme_global_color) !important;
}
</style>