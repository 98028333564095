import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 身份
    token: '',
    // 用户信息
    userInfo: {},
    // 官方信息
    officialInfo: {},
    // 登录状态
    loginStauts: {
      status: false,
      index: 0
    },
    // 导航栏选中
    navIndex: 0,
    // 教育资讯-历史记录
    search_history: []
  },
  getters: {
  },
  mutations: {
    // token
    addToken(state, playod) {
      state.token = playod;
    },

    // 用户信息
    addUserInfo(state, playod) {
      state.userInfo = playod;
    },

    // 登录弹窗状态
    addLoginStauts(state, playod) {
      state.loginStauts = playod;
    },

    // 官方信息
    addOfficialInfo(state, playod) {
      state.officialInfo = playod;
    },

    // 导航栏选中
    addNavIndex(state, playod) {
      sessionStorage.setItem('navIndex', playod);
      state.navIndex = playod;
    },

    // 教育资讯-搜索
    addSearchHistory(state, playod) {
      state.search_history = [];
      if (playod) state.search_history = JSON.parse(playod);
    },
  },
  actions: {
    // token
    addToken(content, playod) {
      content.commit('addToken', playod);
    },

    // 用户信息
    addUserInfo(content, playod) {
      content.commit('addUserInfo', playod);
    },

    // 登录弹窗状态
    addLoginStauts(content, playod) {
      content.commit('addLoginStauts', playod);
    },

    // 官方信息
    addOfficialInfo(content, playod) {
      content.commit('addOfficialInfo', playod);
    },

    // 导航栏选中
    addNavIndex(content, playod) {
      content.commit('addNavIndex', playod);
    },

    // 教育资讯-搜索
    addSearchHistory(content, playod) {
      content.commit('addSearchHistory', playod);
    }
  },
  modules: {
  }
})
