import Vue from 'vue';
import request from '@/api/axios.js';

// 兄弟组件通知
Vue.prototype.$Bus = new Vue();

// 公共跳转
Vue.prototype.$commitJump = function commitJump(url, data) {
    this.$router.push({
        name: url,
        query: data
    })
}

// 返回顶部
Vue.prototype.$backTop = function backTop(value) {
    setTimeout(() => {
        let scrollTop =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop;
        if (scrollTop > 0) {
            scrollTop -= value;
            document.body.scrollTop = document.documentElement.scrollTop =
                scrollTop;
            backTop(value + 10);
        }
    });
}

// 请求用户信息
Vue.prototype.$getUserInfo = function userInfo() {
    return new Promise((resolve, reject) => {
        request.get('/member/information').then((res) => {
            if (res.data.data) {
                this.$addStorageEvent('userInfo', JSON.stringify(res.data.data));
                localStorage.setItem('userInfo', JSON.stringify(res.data.data));
                this.$store.dispatch('addUserInfo', res.data.data);
                resolve(res);
            } else {
                reject({
                    title: res.data.msg,
                    item: res
                })
            }
        })
    })
}

// 判断是否为登录或会员
Vue.prototype.$isTokenORVip = function isTokenORVip() {
    if (!localStorage.getItem('token')) {
        this.$store.dispatch('addLoginStauts', { status: true, index: 0 });
        return false
    }
    try {
        this.$getUserInfo();
        if (this.$store.state.userInfo.is_vip == 0) {
            this.$message({
                message: '需开通会员才可享受权益喔！',
                type: 'warning',
                showClose: true
            });
            return false
        }
    } catch {
        if (this.$store.state.userInfo.is_vip == 0) {
            this.$message({
                message: '需开通会员才可享受权益喔！',
                type: 'warning',
                showClose: true
            });
            return false
        }
    }


    return true
}


// 防抖
Vue.prototype.$_debounce = function debounce(func, delay) {
    var delay = delay || 200;
    var timer;
    return function () {
        var th = this;
        var args = arguments;
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(function () {
            timer = null;
            func.apply(th, args);
        }, delay);
    };
}

// 节流
Vue.prototype.$_throttle = function _throttle(func, delay) {
    var last;
    var timer;
    var delay = delay || 200;
    return function () {
        var th = this;
        var args = arguments;
        var now = +new Date();
        if (last && now - last < delay) {
            clearTimeout(timer);
            timer = setTimeout(function () {
                last = now;
                func.apply(th, args);
            }, delay);
        } else {
            last = now;
            func.apply(th, args);
        }
    }
}

import { Message } from 'element-ui';

// 验证身份信息
export const identityVerify = (id, jump, item) => {
    return new Promise((resolve, reject) => {
        request.get('/permission/course', {id: id}).then((res) => {
            if (res.data.code == 200) {
                if (jump && res.data.data) {
                    setTimeout(() => {
                        window.open(`#/course/detail?id=${id}` + (item ? `&item=${item}` : ''), '_blank');
                    })
                }
                
                resolve({
                    data: res.data.data,
                    msg: res.data.msg
                })
            } else {
                reject({
                    data: res.data.data,
                    msg: res.data.msg
                })
            }

            if (!res?.data?.data) Message.warning({ showClose: true, message: res.data.msg });
        }).catch(() => {
            Message.warning({ showClose: true, message: '网络请求错误，请稍后再试' });

            reject({
                data: false,
                msg: '网络请求错误，请稍后再试',
            })
        })
    })
}